import "../styles/globals.css";
import type { AppProps } from "next/app";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/mui-theme";
import SplitLayout from "../components/SplitLayout";
import TankImage from "../components/TankImage";
import Head from "next/head";
import configureAmplify from "../auth";
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { NotificationContextProvider } from "../context/NotificationContextProvider";
import PlausibleProvider from "next-plausible";

configureAmplify();

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const currentConfig = Auth.configure();
  }, []);

  return (
    <>
      <Head>
        <title>Welcome to HiLo</title>
        <meta name="description" content="Create an account or login" />
        <link rel="icon" href="/favicon.png" type="image/png" />
      </Head>
      <PlausibleProvider domain={process?.env?.NEXT_PUBLIC_PLAUSIBLE_DOMAIN || ""}>
        <ThemeProvider theme={theme}>
          <NotificationContextProvider>
            <SplitLayout
              leftContent={<TankImage fadeIn={true} />}
              rightContent={<Component {...pageProps} />}
            ></SplitLayout>
          </NotificationContextProvider>
        </ThemeProvider>
      </PlausibleProvider>
    </>
  );
}

export default MyApp;
