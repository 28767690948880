import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { createContext, useState } from "react";

interface NotificationDetails {
  alertColor: AlertColor;
  message: string;
}

interface NotificationContextValue {
  showNotification: (alertColor: AlertColor, message: string) => void;
}

export const NotificationContext = createContext<NotificationContextValue | null>(null);

export const NotificationContextProvider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const [notification, setNotification] = useState<NotificationDetails | null>(null);

  function showNotification(alertColor: AlertColor, message: string) {
    setNotification({ alertColor, message });
  }

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      <Snackbar
        open={notification != null}
        autoHideDuration={5000}
        onClose={() => setNotification(null)}
        message={notification?.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={notification?.alertColor || "info"}>{notification?.message}</Alert>
      </Snackbar>
      {props.children}
    </NotificationContext.Provider>
  );
};
