import { Grid } from "@mui/material";
import React from "react";

interface Props {
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}
const SplitLayout: React.FC<Props> = ({ leftContent, rightContent }) => {
  return (
    <Grid container style={{ height: "100%" }}>
      <Grid item xs={12} md={6}>
        {leftContent}
      </Grid>
      <Grid item xs={12} md={6} style={{ backgroundColor: "white" }}>
        {rightContent}
      </Grid>
    </Grid>
  );
};

export default SplitLayout;
