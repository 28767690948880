import { createTheme } from "@mui/material";

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none"
                }
            }
        }
    },
    palette: {
        primary: {
            main: "#0089CF"
        },
        secondary: {
            main: "#ffffff",
            dark: "#efefef",
            contrastText: "#0089CF"
        }
    },
    typography: {
        fontFamily: [
            'DIN Alternate',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(', '),
        h1: {
            fontFamily: "Aquawax-Pro"
        },
        h2: {
            fontFamily: "Aquawax-Pro"
        },
        h3: {
            fontFamily: "Aquawax-Pro"
        },
        h4: {
            fontFamily: "Aquawax-Pro"
        },
        h5: {
            fontFamily: "Aquawax-Pro"
        },
        h6: {
            fontFamily: "Aquawax-Pro"
        }
    }
});

export default theme;   