import React from "react";
import { styled } from "@mui/material";

const TankImageContainer = styled("div")`
  width: 100%;
  height: 100vh;
  background-image: url("/img/welcome-tank-image.jpg");
  background-repeat: noRepeat;
  background-position: bottom;
  background-size: cover;
  @media (max-width: 900px) {
    height: 150px;
    background-position: center;
  }
`;

const LogoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 100px 0px 0px 0px;
  & .hilo-logo {
    width: 500px;
    height: 150px;
  }
  & .welcome-text {
    margin: 40px 0px;
    font-family: Aquawax-Pro;
    font-size: 5rem;
    font-weight: 900;
    color: white;
  }
  @media (max-width: 900px) {
    padding: 0px;
    justify-content: center;
    & .hilo-logo {
      width: 375px;
      height: 112px;
    }
    & .welcome-text {
      display: none;
    }
  }
`;

const TankImage: React.FC<{ fadeIn: boolean }> = ({ fadeIn }) => {
  return (
    <TankImageContainer>
      <LogoContainer>
        <img
          src="/img/hilo-black.png"
          alt=""
          className={fadeIn ? "fade-in hilo-logo" : "hilo-logo"}
        ></img>
        <span className={fadeIn ? "welcome-text scale-in" : "welcome-text"}>Welcome</span>
      </LogoContainer>
    </TankImageContainer>
  );
};

export default TankImage;
